import React, { Component } from "react";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import Select from "react-select";
import { fromJS, List } from "immutable";
import {
	addErrorNotification,
	addInfoNotification,
} from "../../../../actions/notify.actions";
import {
	getLabel,
	SHARES_DEFAULT_CLASSES,
	SHARES_DEFAULT_TYPES,
} from "../../../../constants/shares";

import ShareholderOptionRenderer from "./shareholder-options-renderer";
import ShareholderValueRenderer from "./shareholder-value-renderer";
import ShareTypeOptionRenderer from "./share-type-options-renderer";
import ShareTypeValueRenderer from "./share-type-value-renderer";

import sharesValidator from "../../../helpers/shares.validator";

import NumericInput from "../../../../dumb-components/fields/numeric-input";

import { Scrollbars } from "react-custom-scrollbars";

class ShareDistributionB extends Component {
	static propTypes = {
		intl: intlShape.isRequired,
	};

	constructor(props) {
		super(props);
		this.step = 3;
		this.state = {
			shareholderOptions: [],
			classOfSharesOptions: [],
			transferLimitationOptions: [],
			totalRemaningPerType: {},
			totalRemaning: null,
		};
		this.scrollbarRef = React.createRef();
	}

	componentDidMount() {
		const { transaction, i18n } = this.props;
		let investors = transaction.get("investors");
		let initialShareData = transaction.getIn([
			"handlerData",
			"initialShareData",
		]);
		if (investors && initialShareData) {
			this.parseShareholders(transaction);
			this.parseClassOfShares(transaction);
			this.calcRemaning(transaction);
			this.addSequenceToTheEnd(transaction);
		}

		this.setState({
			transferLimitationOptions: [
				{
					label: i18n.messages["transfer-limitation.pre-emption"],
					value: "pre-emption",
				},
				{
					label: i18n.messages["transfer-limitation.redemption-reservation"],
					value: "redemption-reservation",
				},
				{
					label: i18n.messages["transfer-limitation.redemption-disclaimers"],
					value: "redemption-disclaimers",
				},
				{
					label: i18n.messages["transfer-limitation.consent-disclaimers"],
					value: "consent-disclaimers",
				},
				{
					label: i18n.messages["transfer-limitation.conversion-disclaimer"],
					value: "conversion-disclaimer",
				},
			],
		});
	}

	componentDidUpdate(prevProps) {
		let investors = this.props.transaction.get("investors");
		let initialShareData = this.props.transaction.getIn([
			"handlerData",
			"initialShareData",
		]);

		if (
			this.props.transaction !== prevProps.transaction &&
			investors &&
			initialShareData
		) {
			this.parseShareholders(this.props.transaction);
			this.parseClassOfShares(this.props.transaction);
			this.calcRemaning(this.props.transaction);
			this.addSequenceToTheEnd(this.props.transaction);
		}
	}

	parseClassOfShares(transaction) {
		const { i18n } = this.props;
		const sequences = transaction.getIn(
			["handlerData", "initialSequences"],
			fromJS([]),
		);
		let types = transaction.getIn(
			["handlerData", "initialShareData", "types"],
			fromJS([]),
		);
		types = types
			.map((type) => {
				let classOfShareLabel = getLabel(
					SHARES_DEFAULT_CLASSES,
					type.get("shareClass"),
				);
				let seriesLabel = getLabel(SHARES_DEFAULT_TYPES, type.get("shareType"));
				classOfShareLabel =
					i18n.messages[classOfShareLabel] ||
					decodeURIComponent(classOfShareLabel);
				seriesLabel =
					i18n.messages[seriesLabel] || decodeURIComponent(seriesLabel);

				if (classOfShareLabel === "null") {
					classOfShareLabel = "";
				}
				if (seriesLabel === "null") {
					seriesLabel = "";
				}

				const option = {
					label: `${classOfShareLabel} ${seriesLabel}`,
					value: type.get("type"),
				};
				if (option.label === " ") {
					option.label = "Ospecificerad";
				}

				let numOfSharesLeft = type.get("numOfShares");

				sequences.forEach((seq) => {
					if (seq.get("type") === type.get("type")) {
						let total =
							seq.get("total") < 0 || seq.get("total") == null
								? 0
								: seq.get("total");
						numOfSharesLeft -= parseInt(total);
					}
				});

				numOfSharesLeft = isNaN(numOfSharesLeft) ? 0 : numOfSharesLeft;
				option.label += "[split-here]" + numOfSharesLeft;
				option.disabled = numOfSharesLeft <= 0;
				return option;
			})
			.toArray();

		this.setState({ classOfSharesOptions: types });
	}

	parseShareholders(transaction) {
		let investors = transaction.get("investors");
		if (investors) {
			investors = investors
				.map((investor, id) => {
					const isCapitalInsurance =
						investor.get("investorTypeOfOwner") === "capitalInsurance";
					let investorIdOrInsuranceNr = investor.getIn([
						"investorInformation",
						"id",
					]);
					if (isCapitalInsurance) {
						investorIdOrInsuranceNr = investor.getIn(
							["investorInformation", "insuranceNr"],
							"",
						);
					}
					return {
						label: `${investor.getIn([
							"investorInformation",
							"name",
						])}[split-here]${investorIdOrInsuranceNr}`,
						value: id,
					};
				})
				.toIndexedSeq()
				.toArray();
			this.setState({ shareholderOptions: investors });
		}
	}

	calcRemaning(transaction) {
		let total = transaction.getIn(
			["handlerData", "initialShareData", "numOfTotalShares"],
			0,
		);
		const totalPerTypes = {};
		const types = transaction.getIn(
			["handlerData", "initialShareData", "types"],
			[],
		);
		const sequences = transaction.getIn(
			["handlerData", "initialSequences"],
			[],
		);
		types.forEach((type) => {
			totalPerTypes[type.get("type")] = type.get("numOfShares");
		});

		sequences.forEach((sequence) => {
			const type = sequence.get("type");
			const stotal = sequence.get("total") || 0;
			total = total - stotal;

			totalPerTypes[type] =
				(totalPerTypes[type] ? totalPerTypes[type] : 0) - stotal;
		});

		this.setState({
			totalRemaningPerType: totalPerTypes,
			totalRemaning: total,
		});
	}

	goToNext() {
		let { transaction, onChange } = this.props;
		let sequences = transaction.getIn(["handlerData", "initialSequences"]);
		let shareData = transaction.getIn(["handlerData", "initialShareData"]);
		const types = transaction.getIn([
			"handlerData",
			"initialShareData",
			"types",
		]);
		sequences = this.buildInitialSeqences(sequences, types);

		try {
			sharesValidator.validate(shareData.toJS(), sequences.toJS());
			transaction = transaction.setIn(
				["handlerData", "initialSequences"],
				sequences,
			);
			onChange(transaction);
			this.props.onNext(this.step + 1);
			this.props.jumpToStep(this.step + 1);
		} catch (e) {
			this.props.addErrorNotification({
				text: e.message,
			});
		}
	}

	goToPrevious() {
		this.props.onPrevious(this.step - 1);
		this.props.jumpToStep(this.step - 1);
	}

	buildInitialSeqences(sequences) {
		let lastSequenceTo = 0;

		sequences = sequences.skipLast(1);
		// Calc the sequences
		sequences = sequences.map((sequence) => {
			sequence = sequence.set("sequenceFrom", lastSequenceTo + 1);
			lastSequenceTo = lastSequenceTo + sequence.get("total");
			sequence = sequence.set("sequenceTo", lastSequenceTo);
			return sequence;
		});
		return sequences;
	}

	addSequenceToTheEnd(transaction) {
		let sequences = transaction.getIn(["handlerData", "initialSequences"]);
		this.scrollbarRef?.current?.scrollToBottom?.();

		const emptySequence = fromJS({
			investmentId: null,
			sequenceFrom: null,
			sequenceTo: null,
			total: null,
			type: null,
			transactionLimitations: [],
		});

		if (!sequences || sequences.size < 1) {
			transaction = transaction.setIn(
				["handlerData", "initialSequences"],
				List([emptySequence]),
			);
			this.props.onChange(transaction);
		} else {
			let lastSequence = null;

			lastSequence = sequences.last();
			if (
				lastSequence.get("investmentId") ||
				(lastSequence.get("total") && lastSequence.get("total") > 0) ||
				lastSequence.get("type") ||
				(lastSequence.get("transactionLimitations") &&
					lastSequence.get("transactionLimitations").size > 0)
			) {
				sequences = sequences.push(emptySequence);
				transaction = transaction.setIn(
					["handlerData", "initialSequences"],
					List(sequences),
				);
				this.props.onChange(transaction);
			}
		}
	}

	formatNumerInput(num) {
		const { formatNumber } = this.props.intl;

		if (isNaN(num)) {
			return num;
		}
		//return num;
		return formatNumber(num);
	}

	onChange(index, field, val) {
		let { transaction, onChange } = this.props;
		if (field === "type") {
			const types = transaction.getIn([
				"handlerData",
				"initialShareData",
				"types",
			]);
			const type = types.find((value) => {
				return value.get("type") === val;
			});
			transaction = transaction.setIn(
				["handlerData", "initialSequences", index, "transactionLimitations"],
				type && type.get("transactionLimitations"),
			);
		}

		transaction = transaction.setIn(
			["handlerData", "initialSequences", index, field],
			val,
		);

		onChange(transaction);
	}

	deleteRow(index) {
		let transaction = this.props.transaction;
		transaction = transaction.deleteIn([
			"handlerData",
			"initialSequences",
			index,
		]);
		this.props.onChange(transaction);
	}

	renderRow(sequence, index) {
		const {
			shareholderOptions,
			classOfSharesOptions,
			transferLimitationOptions,
			totalRemaning,
		} = this.state;
		const { i18n, transaction } = this.props;
		const sequences = transaction.getIn(["handlerData", "initialSequences"]);
		let sequenceFrom = 1;
		let sequenceTo = null;

		if (index >= 0) {
			sequences.forEach((seq, i) => {
				if (i < index) {
					sequenceFrom = sequenceFrom + seq.get("total", 0);
				}
			});
		}

		sequenceTo = sequence.get("total")
			? sequenceFrom + sequence.get("total") - 1
			: null;

		if (sequences.size === index + 1) {
			if (totalRemaning <= 0) {
				sequenceTo = null;
				return null;
			}
		}
		const sequenceError = sequenceTo && sequenceTo < sequenceFrom;
		const sequenceToCN = sequenceError
			? "list__item__input list__item__input--pad-right has-error"
			: "list__item__input list__item__input--pad-right";

		return (
			<div
				key={index}
				className="list__item list__item--no-height list__item--align-start"
			>
				<span className="list__item__text-area share-distribution-a__list-body__total list__item__input--margin-left">
					<span className="list__item__input list__item__input--pad-right">
						<NumericInput
							type="text"
							className="form-control text--align-right"
							disabled={true}
							value={sequenceFrom}
							onChange={() => {}}
						/>
					</span>
				</span>
				<span className="list__item__text-area share-distribution-a__list-body__total">
					<span className={sequenceToCN}>
						<NumericInput
							type="text"
							className="form-control text--align-right"
							value={sequenceTo || null}
							onChange={(val) => {
								this.onChange(
									index,
									"total",
									(val ? val : 0) - sequenceFrom + 1,
								);
							}}
						/>
					</span>
				</span>
				<span className="list__item__text-area share-distribution-a__list-body__shareholder">
					<span className="list__item__input list__item__input--pad-right">
						<Select
							value={sequence.get("investmentId")}
							options={shareholderOptions}
							clearable={false}
							placeholder={i18n.messages["select_placeholder"]}
							optionComponent={ShareholderOptionRenderer}
							valueComponent={ShareholderValueRenderer}
							onChange={(val) => {
								this.onChange(index, "investmentId", val);
							}}
							simpleValue={true}
							className={index > 2 ? "Select--up" : ""}
						/>
					</span>
				</span>
				<span className="list__item__text-area share-distribution-a__list-body__class-of-shares">
					<span className="list__item__input list__item__input--pad-right">
						<Select
							value={sequence.get("type")}
							options={classOfSharesOptions}
							placeholder={i18n.messages["select_placeholder"]}
							optionComponent={ShareTypeOptionRenderer}
							valueComponent={ShareTypeValueRenderer}
							clearable={false}
							onChange={(val) => {
								this.onChange(index, "type", val);
							}}
							simpleValue={true}
							className={index > 2 ? "Select--up" : ""}
						/>
					</span>
				</span>
				<span className="list__item__text-area share-distribution-a__list-body__transaction-restrictions">
					<span className="list__item__input list__item__input--pad-right">
						<Select
							value={
								sequence.get("transactionLimitations")
									? sequence.get("transactionLimitations").toJS()
									: []
							}
							multi
							clearable={false}
							options={transferLimitationOptions}
							placeholder={i18n.messages["select_placeholder"]}
							onChange={(val) => {
								this.onChange(
									index,
									"transactionLimitations",
									List(val.split(",")),
								);
							}}
							simpleValue={true}
							className={index > 2 ? "Select--up" : ""}
						/>
					</span>
				</span>
				<span className="list__item__body list__item__body--no-flex">
					<span className="list__item__input text--align-right">
						<button
							type="button"
							className="btn btn-default"
							onClick={this.deleteRow.bind(this, index)}
						>
							<i className="fa fa-times"></i>
						</button>
					</span>
				</span>
			</div>
		);
	}

	renderList() {
		const sequences = this.props.transaction.getIn([
			"handlerData",
			"initialSequences",
		]);

		return (
			<div className="list list--striped list--table">
				<div className="share-distribution-a__list-header">
					<div className="share-distribution-a__list-header__total">
						<FormattedMessage id="from" />
					</div>
					<div className="share-distribution-a__list-header__total">
						<FormattedMessage id="to" />{" "}
					</div>

					<div className="share-distribution-a__list-header__shareholder">
						<FormattedMessage id="shareholder" />
					</div>
					<div className="share-distribution-a__list-header__class-of-shares">
						<FormattedMessage id="shares.class_of_shares" />
					</div>
					<div className="share-distribution-a__list-header__transaction-restrictions">
						<FormattedMessage id="shares.transaction_restrictions" />
					</div>
				</div>
				<div className="list__body">
					{sequences && sequences.map(this.renderRow.bind(this))}
				</div>
			</div>
		);
	}

	render() {
		return (
			<div
				className={`account-wizard__content account-wizard--animated ${this.props.className}`}
			>
				<div className="account-wizard__header">
					<h2 className="account-wizard__title">
						<span className="account-wizard__step">4</span>{" "}
						<FormattedMessage id="shares.create_share_sequences_for_the_share_register" />
					</h2>
					<p>
						<FormattedMessage id="shares.create_share_sequences_for_the_share_register_information" />
					</p>
				</div>
				<div className="account-wizard__body">
					<div className="i-panel i-panel--white">
						<div className="i-panel__body">
							<Scrollbars
								ref={this.scrollbarRef}
								autoHeight
								autoHeightMin={270}
							>
								{this.renderList()}
							</Scrollbars>
						</div>
					</div>
				</div>
				<div className="account-wizard__footer">
					<button
						type="button"
						className="btn btn-account-wizard"
						onClick={this.goToPrevious.bind(this)}
					>
						<FormattedMessage id="previous" />
					</button>
					<button
						type="button"
						className="btn btn-account-wizard"
						onClick={this.goToNext.bind(this)}
					>
						<FormattedMessage id="next" />
					</button>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return { i18n: state.i18n };
}

const ShareDistributionBInjectIntl = injectIntl(ShareDistributionB);
const ShareDistributionBConnected = connect(mapStateToProps, {
	addErrorNotification,
	addInfoNotification,
})(ShareDistributionBInjectIntl);
export default ShareDistributionBConnected;
