import req from "../modules/request.module";
import { WIKI_FETCH, WIKI_SAVE, WIKI_ERROR } from "./types";

/**
 * Action for fetching a wiki or creating a new one
 * @param {String} wikiId — the id of the wiki
 * @param {String} lang — the requested content language
 */
export function fetchWiki(id, lang) {
	return function (dispatch) {
		req
			.get(`/wiki/${id}?lang=${lang}`)
			.then((response) => {
				dispatch({ type: WIKI_FETCH, payload: response.data });
			})
			.catch(() => {
				dispatch(wikiError("Could't find wiki."));
			});
	};
}

/**
 * Action for editing existing wiki.
 * @param {String} content — the content to save
 * @param {String} id — the wiki id to update to or create
 * @param {String} language — the language of the wiki content
 */
export function editWiki(content, id, lang) {
	return function (dispatch) {
		req
			.put(`/wiki/${id}`, { content, lang })
			.then((response) => {
				dispatch({ type: WIKI_SAVE, payload: response.data });
			})
			.catch(() => {
				dispatch(wikiError("Could't save or edit wiki."));
			});
	};
}

/**
 * Action for dispatching a wiki error
 */
function wikiError(error) {
	return {
		type: WIKI_ERROR,
		payload: error,
	};
}
