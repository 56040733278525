/**
 * User reducer
 * @module reducers/user.reducer
 */

import { WIKI_FETCH, WIKI_SAVE } from "../actions/types";

/** The initial state object for user. */
const INITIAL_STATE = { wikiText: {} };

/** Export the reducer */
export default function (state = INITIAL_STATE, action) {
	const temp = state.wikiText;

	switch (action.type) {
		case WIKI_FETCH:
			temp["wiki-" + action.payload.id] = action.payload.text;
			return { ...state, wikiText: temp };

		case WIKI_SAVE:
			temp["wiki-" + action.payload.id] = action.payload.text;
			return { ...state, wikiText: temp };

		default:
			return state;
	}
}
