import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import ShareTypesTable from "../../../framework/share-types";
import { List } from "immutable";
import {
	addErrorNotification,
	addInfoNotification,
} from "../../../../actions/notify.actions";
import sharesValidator from "../../../helpers/shares.validator";
import { Scrollbars } from "react-custom-scrollbars";

class ShareTypes extends Component {
	constructor(props) {
		super(props);
		this.step = 1;
	}

	onChange(transaction) {
		this.props.onChange(transaction);
	}

	goToNext() {
		const { setupType } = this.props;
		const numOfTotalShares = this.props.transaction.getIn([
			"handlerData",
			"initialShareData",
			"numOfTotalShares",
		]);
		let types = this.props.transaction.getIn(
			["handlerData", "initialShareData", "types"],
			List(),
		);
		try {
			sharesValidator.validateShareTypes(types, numOfTotalShares, setupType);
			this.props.onNext(this.step + 1);
			this.props.jumpToStep(this.step + 1);
		} catch (e) {
			this.props.addErrorNotification({
				text: e.message,
			});
		}
	}

	goToPrevious() {
		this.props.onPrevious(this.step - 1);
		this.props.jumpToStep(this.step - 1);
	}

	render() {
		const { setupType } = this.props;
		return (
			<div
				className={`account-wizard__content account-wizard--animated ${this.props.className}`}
			>
				<div className="account-wizard__header">
					<h2 className="account-wizard__title">
						<span className="account-wizard__step">2</span>{" "}
						<FormattedMessage id="shares_setup_optional" />
					</h2>
					<p>
						<FormattedMessage id="share_types_information" />
					</p>
				</div>
				<div className="account-wizard__body">
					<div className="i-panel i-panel--white">
						<div className="i-panel__body">
							<Scrollbars autoHeight autoHeightMin={270}>
								<ShareTypesTable
									setupType={setupType}
									onChange={this.onChange.bind(this)}
									transaction={this.props.transaction}
								/>
							</Scrollbars>
						</div>
					</div>
				</div>
				<div className="account-wizard__footer">
					<button
						type="button"
						className="btn btn-account-wizard"
						onClick={this.goToPrevious.bind(this)}
					>
						<FormattedMessage id="previous" />
					</button>
					<button
						type="button"
						className="btn btn-account-wizard"
						onClick={this.goToNext.bind(this)}
					>
						<FormattedMessage id="next" />
					</button>
				</div>
			</div>
		);
	}
}

export default connect(null, { addErrorNotification, addInfoNotification })(
	ShareTypes,
);
