import React from "react";

import {
	Carousel,
	CarouselContent,
	CarouselItem,
} from "@/components/ui/carousel";
import { CarouselDots } from "../../components/ui/carousel";
import { Button } from "../../components/ui/button";
import useDialogModal from "../../hooks/useDialogModal";
import { GraduationCap, Lightbulb } from "lucide-react";
import { Trans, useTranslation } from "react-i18next";

export function CredentialsCarousel() {
	const { dialogElement, alert } = useDialogModal();
	const { t } = useTranslation();
	return (
		<div className="mt-[118px] mb-[50px] flex flex-col justify-center p-20 w-full h-full">
			<Carousel className="w-full h-full">
				<CarouselContent className="h-full">
					<CarouselItem>
						<div className="flex flex-col w-full h-full gap-12 items-center select-none [&_h2]:text-xl [&>p]:text-background/80 [&>p>strong]:text-background [&_s]:opacity-40">
							<div className="flex flex-row-reverse gap-8 [&>h2>strong]:text-brand">
								<GraduationCap
									strokeWidth={0.8}
									size={70}
									className="mt-[-6px] opacity-20"
								/>
								<h2 className="flex-1">
									<Trans i18nKey={"credentials_carousel.get_started.heading"} />
								</h2>
							</div>
							<Trans i18nKey="credentials_carousel.get_started.body" />
							<Button
								variant="secondary"
								className="bg-transparent text-background hover:bg-background/20"
								size="lg"
								onClick={() =>
									alert(
										t("credentials_carousel.get_started.modal.title"),
										<div className="grid grid-cols-[60%_40%] [&_h2]:text-xl [&_h2]:font-semibold [&_h2]:mb-6 px-20">
											<div className="[&_ul]:list-disc [&_ul]:py-10 [&_ul]:px-20 [&_ul>li]:mb-4">
												<Trans i18nKey="credentials_carousel.get_started.modal.body.what_is_covered" />
											</div>
											<div className="flex flex-col gap-12 ">
												<div>
													<h2>
														{t(
															"credentials_carousel.get_started.modal.body.offer_title",
														)}
													</h2>
													<div className="bg-accent p-4 rounded">
														<div className="flex justify-between">
															<p>
																8 x{" "}
																{t(
																	"credentials_carousel.get_started.modal.body.offer_item",
																)}{" "}
																(1 295 SEK)
															</p>
															<p>10 360 SEK</p>
														</div>
														<div className="flex justify-between">
															<p>50% {t("subscriptions.coupon.discount")}</p>
															<p>- 5 180 SEK</p>
														</div>
														<div className="flex justify-between font-bold text-lg">
															<p>{t("subscriptions.invoice.total")}</p>
															<p>= 5 180 SEK</p>
														</div>
													</div>
												</div>
												<div>
													<Trans
														i18nKey={
															"credentials_carousel.get_started.modal.body.how_to_apply"
														}
														components={[
															<Button
																key="mailto"
																variant="link"
																className="p-0"
																onClick={() => {
																	window.location.href =
																		"mailto:info@invono.se";
																}}
															/>,
														]}
													/>
												</div>
											</div>
										</div>,
										{ size: "lg" },
									)
								}
							>
								<Lightbulb /> {t("generic.learn_more")}
							</Button>
						</div>
					</CarouselItem>
					{/* <CarouselItem>
						<div className="flex flex-col w-full h-full gap-10">
							<h2 className="text-xl text-center">Ready for next item</h2>
							<p>...</p>
						</div>
					</CarouselItem> */}
				</CarouselContent>
				<CarouselDots />
			</Carousel>
			{dialogElement}
		</div>
	);
}

export default CredentialsCarousel;
